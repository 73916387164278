<template>
  <div class="contact-page">
    <v-container>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <div v-if="!emailSent">
        <v-row class="mb-10">
          <v-col cols="12" md="6" class="d-none d-md-block">
            <GmapMap
              :center="{ lat: -6.217821163356334, lng: 106.76050093419474 }"
              :zoom="10"
              style="width: 100%; height: 630px"
            >
              <GmapMarker
                :position="{ lat: -6.217821163356334, lng: 106.76050093419474 }"
                :clickable="true"
                :draggable="true"
                @click="
                  center = { lat: -6.217821163356334, lng: 106.76050093419474 }
                "
              />
            </GmapMap>
          </v-col>
          <v-col cols="12" md="6">
            <h2 class="mb-5 d-none d-sm-block">
              Thank you for your interest in UnicoCorn
            </h2>
            <h1 class="mb-5 d-sm-none text-center">
              Thank you for your <br />
              interest in UnicoCorn
            </h1>
            <div class="d-flex">
              <div>
                <v-icon left color="primary">mdi-email</v-icon>
              </div>
              <p>contact@unicocorn.com</p>
            </div>
            <div class="d-flex">
              <div>
                <v-icon left color="primary">mdi-map-marker</v-icon>
              </div>
              <p>
                Jl. Swadarma Raya No.38, RT.11/RW.3, Srengseng Kec. Kembangan,
                Kota Jakarta Barat Daerah Khusus Ibukota Jakarta 11630
              </p>
            </div>
            <div class="d-flex">
              <div>
                <v-icon left color="primary">mdi-phone</v-icon>
              </div>
              <p>+62 812 8946 4536</p>
            </div>
            <v-divider class="my-5" />
            <p class="grey--text">
              Want to know more about our services? Submit this form and our
              sales representative will contact you soon.
            </p>
            <v-form ref="form" v-model="valid" lazy-validation class="pt-4">
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    label="Name"
                    required
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    v-model="company"
                    label="Company"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="Email"
                    required
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    label="Phone number"
                    type="number"
                    v-model="phone"
                    :rules="phoneRules"
                    required
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" class="py-0">
                  <v-textarea
                    outlined
                    name="input-7-4"
                    label="Message"
                    v-model="message"
                  ></v-textarea>
                </v-col>
                <v-col class="d-flex justify-end py-0">
                  <v-btn
                    class="btn-full"
                    :disabled="!valid || sending"
                    color="primary"
                    :loading="sending"
                    @click="sendMail"
                    >Send Message</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col cols="12" md="6" class="d-block d-md-none mt-16">
            <GmapMap
              :center="{ lat: -6.217821163356334, lng: 106.76050093419474 }"
              :zoom="10"
              style="width: 100%; height: 630px"
              :style="$vuetify.breakpoint.name != 'xs' ? 'height: 630px' : 'height: 400px'"
            >
              <GmapMarker
                :position="{ lat: -6.217821163356334, lng: 106.76050093419474 }"
                :clickable="true"
                :draggable="true"
                @click="
                  center = { lat: -6.217821163356334, lng: 106.76050093419474 }
                "
              />
            </GmapMap>
          </v-col>
        </v-row>

        <div class="text-center ma-2">
          <v-snackbar v-model="emailFailed" color="red accent-2">
            Failed to send email. Please try again.

            <template v-slot:action="{ attrs }">
              <v-btn text v-bind="attrs" @click="emailFailed = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </div>
      <div v-else class="d-flex justify-center">
        <div class="text-center">
          <v-img
            :src="require('../assets/send-1.svg')"
            class="my-3"
            contain
            height="128"
          />
          <h2>Your message has been sent</h2>
          <p class="grey--text">
            You will receive an email if and when your message is approved,
            <br />
            and we'll explained clearly our products to you.
          </p>
          <v-btn color="primary" to="/" class="mt-2">Back to Home</v-btn>
          <p class="grey--text mt-5 mb-2">Or</p>
          <v-btn color="primary" text @click="emailSent = false"
            >Send another message</v-btn
          >
        </div>
      </div>
    </v-container>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import "isomorphic-fetch";
import { SERVER_HOST } from "../../server/utils/env.json";

export default {
  components: {
    Footer,
  },
  data: () => ({
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Contact",
        disabled: true,
        href: "/contact",
      },
    ],
    sending: false,
    emailFailed: false,
    emailSent: false,
    valid: true,
    name: "",
    nameRules: [(v) => !!v || "Name is required"],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    phone: "",
    phoneRules: [
      (v) => !!v || "Phone number is required",
      (v) => (v && v.length > 5) || "Phone number must be at least 5 digits",
      (v) => (v && v.length <= 16) || "Phone must be less than 16 digits",
    ],
    company: "",
    message: "",
  }),

  mounted() {
    window.scrollTo(0, 0);
  },

  methods: {
    sendMail() {
      const content = {
        name: this.name,
        company: this.company,
        email: this.email,
        phone: this.phone,
        message: this.message,
      };

      this.$refs.form.validate();

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(content),
        redirect: "follow",
      };

      if (this.$refs.form.validate()) {
        this.sending = true;
        fetch(`${SERVER_HOST}/api/email`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            console.log("result: ", result);
            this.sending = false;
            if (result.status === "Success") {
              this.$refs.form.reset();
              this.emailSent = true;
              window.scrollTo(0, 0);
              console.log("Email sent.");
            } else {
              console.log("Failed to send email.");
              this.emailFailed = true;
            }
          })
          .catch((err) => {
            this.sending = false;
            this.emailFailed = true;
            console.log("error: ", err);
          });
      }
    },
  },
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>

<style scoped>
@media (max-width: 1264px) {
  .v-application .contact-page .mb-10 {
    margin-bottom: 0 !important;
  }

  .contact-page .container {
    padding-bottom: 0;
  }
}
@media (max-width: 595px) {
  .contact-page .mb-5 {
    margin-bottom: 16px !important;
  }
  .v-icon--left {
    margin-right: 12px !important;
  }
  .contact-page .my-5 {
    margin-top: 8px !important;
  }
  .btn-full {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
    max-width: auto;
  }
}
</style>
